import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { OptionsContext } from '/src/contexts/OptionsContext'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const LogoLink = ({ useAlternativeBranding, branding }) => {
    const [options] = useContext(OptionsContext)

    return (
        <Link className="c-header__logo-link" to="/">
            {useAlternativeBranding && branding === 'insurance-hero' ? (
                <StaticImage
                    src={'../../../../images/insurance-hero/ih-logo.png'}
                    width={500}
                    alt={`Insurance Hero, powered by ${options.siteData.title}`}
                />
            ) : (
                <StaticImage src={'../../../../images/pl-logo.png'} width={300} alt={options.siteData.title} />
            )}
        </Link>
    )
}

export default LogoLink

LogoLink.propTypes = {
    useAlternativeBranding: PropTypes.bool,
    branding: PropTypes.oneOf(['insurance-hero'])
}
